.master_container {
    display: flex;
    flex-direction: column;
}

.logo {
    margin: 10px;
    width: 70px;
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navButtons_containers {
    display: inherit;
    flex-direction: row;
}

.content_container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.buttons {
    margin-left: 7px;
    margin-right: 50px;
}

.text-finisher {
    text-align: center;
    padding-bottom: 1rem;
    line-height: 2rem;
    font-weight: 300;
}

#backlog-title {
    font-weight: 500;
    font-size: 3rem;
    color: #8a44ab;
}

#book-title {
    font-weight: 500;
    font-size: 1.0rem;
}

#text_container {
    margin: 10px;
    padding-left: 90px;
    padding-right: 90px;
}

.icon-style {
    font-size: 40px;
    cursor: pointer;
}
